import { constants } from "../common/constants";
import styles from "./impressum.module.css";

const Impressum = () => {
  return (
    <div className={styles.impressumWraper}>
      <div className={styles.impressumContent}>
        <div className="container">
          <h1>Impressum</h1>

          <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
          <p>
            Nguyen Thi Bich Hien
            <br />
            Moon Restaurant
            <br />
            Dekan-Wagner-Stra&szlig;e, 22
            <br />
            84032 Altdorf
          </p>

          <h2>Kontakt</h2>
          <p>
            Telefon: {constants.OWNER_NUMBER}
            <br />
            E-Mail: {constants.EMAIL}
          </p>

          <h2>Redaktionell verantwortlich</h2>
          <p>
            Nguyen Thi Bich Hien
            <br />
            Dekan-Wagner-Stra&szlig;e 22 <br />
            84032 Altdorf
          </p>

          <h2>EU-Streitschlichtung</h2>
          <p>
            Die Europ&auml;ische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:{" "}
            <a
              href="https://ec.europa.eu/consumers/odr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://ec.europa.eu/consumers/odr/
            </a>
            .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>

          <h2>
            Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
          </h2>
          <p>
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>

          <p>
            Quelle: <a href="https://www.e-recht24.de">eRecht24</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Impressum;
