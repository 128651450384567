import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import scrollBanner from "../../assets/images/scroll-banner.jpg";

const ScrollBanner = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="scroll-banner-outer">
      <img src={scrollBanner} alt="Scroll Banner" data-aos="fade-up" />
    </div>
  );
};

export default ScrollBanner;
