import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button } from "react-bootstrap";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import "aos/dist/aos.css";
import AOS from "aos";
import { useEffect } from "react";

const WelcomeSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const scrollToMotivationSection = () => {
    const motivationSection = document.querySelector(".motivation-section");
    if (motivationSection) {
      setTimeout(() => {
        motivationSection.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  };

  return (
    <div className="welcome-section-wrapper" data-aos="fade-up">
      <div className="welcome-section" data-aos="fade-up">
        <h1>Herzlich Willkommen bei</h1>
        <span className="logo-text">Moon Restaurant</span>
        <p className="motto-text">"Sushi & Bar"</p>

        <div className="infoPlaceholder">
          <p>
            Betriebsurlaub: 20.01. - 20.02.2025. <br />
            Ab 21.02.2025, 17:00 Uhr, wieder geöffnet.
          </p>
        </div>

        <hr className="spacer" />
        <div className="button-wrapper">
          <Button
            variant="outline-light"
            className="mr-3"
            href={`${process.env.PUBLIC_URL}/MITTAGSKARTE.pdf`}
            target="_blank"
          >
            Mittagskarte <RestaurantMenuIcon />
          </Button>
          <Button
            variant="outline-light"
            href={`${process.env.PUBLIC_URL}/ABENDKARTE.pdf`}
            target="_blank"
          >
            Abendkarte <RestaurantMenuIcon />
          </Button>
        </div>
      </div>
      <div className="arrow-icon-wrapper" onClick={scrollToMotivationSection}>
        <KeyboardArrowDownIcon sx={{ fontSize: 50, color: "white" }} />
      </div>
    </div>
  );
};

export default WelcomeSection;
