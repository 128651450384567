import React from "react";
import styles from "./newsBanner.module.css";

const NewsBanner: React.FC = () => {
  return (
    <div className={styles.newsBanner}>
      <h5>
        Selbstabholer Spezial! 🌟 10%{" "}
        <a
          href={`${process.env.PUBLIC_URL}/ABENDKARTE.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Abendkarte
        </a>{" "}
        🍽️✨
      </h5>
    </div>
  );
};

export default NewsBanner;
