import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Button } from "react-bootstrap";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import motivationImage from "../../assets/images/moon-restaurant.jpg";
import foodImage from "../../assets/images/motivation-3.jpg";

const MotivationSection = () => {
  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  return (
    <section className="motivation-section-wrapper">
      <div className="motivation-section">
        <div className="motivation-text" data-aos="fade-right">
          <h2>Motivation</h2>
          <hr />
          <p data-aos="fade-right">
            Willkommen im <span className="restaurant-name">Moon</span>{" "}
            Restaurant! <br /> <br /> Genießen Sie eine kulinarische Reise durch
            die Welt der asiatischen Küche. Unsere Gerichte aus frischen Zutaten
            werden kunstvoll präsentiert und verzaubern Sie mit Aromen und
            Texturen. <br /> <br /> Bei uns im{" "}
            <span className="restaurant-name">Moon</span> geht es nicht nur um
            Mahlzeiten, sondern um ein unvergessliches Erlebnis. Wir bieten
            authentische asiatische Gerichte, die den Gaumen erfreuen und die
            Seele nähren. Unser stilvolles Ambiente lädt zum Verweilen ein, egal
            ob alleine, mit Freunden oder Familie. Unser hervorragender Service
            wird Sie begeistern und dazu einladen, immer wieder zurückzukehren.{" "}
            <br /> <br /> Besuchen Sie uns im{" "}
            <span className="restaurant-name">Moon</span> Restaurant und erleben
            Sie unsere Liebe zur asiatischen Küche in einer unvergesslichen
            Atmosphäre.
          </p>
        </div>
        <div className="motivation-image">
          <img src={motivationImage} alt="restaurant" data-aos="fade-left" />
        </div>
      </div>
      <div className="motivation-section-second">
        <div className="motivation-text" data-aos="fade-left">
          <h2>Erlebe die Vielfalt der asiatischen Küche </h2>
          <hr />
          <p>
            Besuch uns mal und genieße die kulinarische Vielfalt der asiatischen
            Küche!
          </p>
          <h4>
            {" "}
            <DirectionsBusIcon /> Öffentliche Verkehrsmittel:{" "}
          </h4>
          <p>
            Wenn Sie mit öffentlichen Verkehrsmitteln anreisen, können Sie die{" "}
            <strong>Buslinien: 8, 108, 552, 570 oder 572 nehmen</strong> und an
            der Haltestelle "Altdorf - Hahn" aussteigen. Von dort aus sind es
            nur wenige Gehminuten bis zum Restaurant. <br /> <br /> Falls Sie
            mit dem Auto anreisen gibt es{" "}
            <strong>ausreichend Parkplätze in der Nähe des Restaurants</strong>.
          </p>
          <h4>
            {" "}
            <QueryBuilderIcon /> Unsere Öffnungszeiten:{" "}
          </h4>
          <ul>
            <li>Mo.: Ruhetag</li>
            <li>Di. – Do.: 11:30 - 15:00, 17:00 - 22:30 Uhr</li>
            <li>Fr. - So.: 12:00 - 15:00, 17:00 - 22:30 Uhr</li>
          </ul>
          <h4>
            <EuroSymbolIcon /> Zahlungsoptionen:
          </h4>
          <ul>
            <li className="attention-text">
              Bitte beachten Sie: Nur <strong>Barzahlung</strong> ist möglich!
            </li>
          </ul>
          <br />
          <div className="foodMenu-buttons">
            <Button
              variant="outline-light"
              className="mr-3"
              href={`${process.env.PUBLIC_URL}/MITTAGSKARTE.pdf`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Mittagskarte <RestaurantMenuIcon />
            </Button>
            <Button
              variant="outline-light"
              href={`${process.env.PUBLIC_URL}/ABENDKARTE.pdf`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Abendkarte <RestaurantMenuIcon />
            </Button>
          </div>
          <br />
        </div>
        <div className="motivation-image" data-aos="fade-right">
          <img src={foodImage} alt="restaurant" />
        </div>
      </div>
    </section>
  );
};

export default MotivationSection;
