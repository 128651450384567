import galleryImage1 from "../../assets/images/food-1.jpg";
import galleryImage2 from "../../assets/images/food-2.jpg";
import galleryImage3 from "../../assets/images/food-3.jpg";
import galleryImage4 from "../../assets/images/food-4.jpg";
import galleryImage5 from "../../assets/images/food-5.jpg";
import galleryImage6 from "../../assets/images/food-6.jpg";
import galleryImage7 from "../../assets/images/food-7.jpg";
import galleryImage8 from "../../assets/images/food-8.jpg";
import galleryImage9 from "../../assets/images/food-9.jpg";
import galleryImage10 from "../../assets/images/food-10.jpg";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const GallerySection = () => {
  useEffect(() => {
    AOS.init({
      duration: 300,
      easing: "ease-in-out",
    });
  }, []);

  const images = [
    galleryImage1,
    galleryImage2,
    galleryImage3,
    galleryImage4,
    galleryImage5,
    galleryImage6,
    galleryImage7,
    galleryImage8,
    galleryImage9,
    galleryImage10,
  ];

  return (
    <>
      <h2 className="section-title" data-aos="fade-up">
        Galerie
      </h2>
      <div className="gallery-section-outer">
        <div className="gallery-section">
          {images.map((image, index) => (
            <div
              className="gallery-image"
              key={index}
              data-aos="fade-left"
              data-aos-delay={`${index * 200}`}
              data-aos-offset="200"
            >
              <img src={image} alt={`galleryimage-${index}`} />
              <div className="overlay"></div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default GallerySection;
